<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-90 center pv3">
                <div class="w-100">
                    <div class="pad16">
                        <p class="pb2 f3 b">
                            Bulk Update
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickModalIcon"
                                    @click="modalBulk = true"
                                    style="cursor: pointer; padding-bottom: 1px"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="modalBulk">
                            You can upload your Sales from Excel to make your Account up-to-date. Click
                            <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                        </div>
                        <p>Upload your Sales from Excel to make your Account up-to-date.</p>
                    </div>
                    <div class="pv3 flex w-100" style="justify-content: end"><button class="btn2">Preview</button></div>

                    <div class="w-100 flex items-center justify-center pt3">
                        <label
                            for="file"
                            class="w-40-l w-100 flex flex-column gap16 items-center pa3"
                            style="border: 1px dashed #d0d5dd; border-radius: 8px"
                        >
                            <p><img :src="require('@/assets/images/fileUpload.svg')" /></p>
                            <p>
                                <span class="b blueColor">Click to upload</span>
                                <span style="color: #475367"> or drag and drop</span>
                            </p>
                            <p style="color: #98a2b3; font-size: 12px">XLSX (max. 10MB)</p>
                            <p><img :src="require('@/assets/images/Dividers.svg')" /></p>
                            <p class="styledBtn">Browse Files</p>
                        </label>
                    </div>

                    <div class="w-100 flex justify-center pt3">
                        <div class="flex w-40-l w-100 flex-column justify-center">
                            <p class="pv2 b" style="color: #939393">File</p>
                            <div class="w-100 flex flex-column gap16 pa3" style="border: 1px dashed #d0d5dd; border-radius: 8px">
                                <div class="flex justify-between">
                                    <p class="flex items-center gap8">
                                        <span
                                            ><img :src="require('@/assets/images/excelIcon.svg')" height="20px" width="20px"
                                        /></span>
                                        <span>{{ fileName || 'No file chosen' }}</span>
                                    </p>
                                    <p class="flex items-center gap8">
                                        <span>{{ fileSize }}</span>
                                        <span @click="removeFile" style="cursor: pointer"
                                            ><img :src="require('@/assets/images/close.svg')" height="10px" width="10px"
                                        /></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form @submit.prevent="onSubmit">
                        <input
                            type="file"
                            name="imageFile"
                            id="file"
                            class="product-input"
                            style="display: none"
                            @change="onFileChange"
                            accept="text/csv"
                            required
                        />
                        <div class="flex justify-center">
                            <button :disabled="!state.imageFile" class="mt3">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Update Inventory' }}
                            </button>
                        </div>
                    </form>
                    <div class="pt4">
                        <h3 class="b">
                            Here's a simple step-by-step guide on how to update product information on Simplebks in bulk:
                        </h3>
                        <div>
                            <p class="flex items-center gap8 pv3 box-border-bottom">
                                <span><img :src="require('@/assets/images/1.svg')" height="20px" width="20px" /></span>
                                <span
                                    >Download the product list <a href="" @click.prevent="downloadInventoryTemplate" class="b"
                                        >here</a
                                    >: This is a file that has information about all your products in CSV (It's like a table that
                                    you can open in programs like Microsoft Excel)</span
                                >
                            </p>

                            <p class="flex items-center gap8 pv3 box-border-bottom">
                                <span><img :src="require('@/assets/images/2.svg')" height="20px" width="20px" /></span>
                                <span
                                    >Make changes to the product information: Find the product you want to update and change its
                                    name, quantity in stock, buying price, or selling price. You can do this for as many products
                                    as you want</span
                                >
                            </p>
                            <div class="flex items-center gap8 pv3 box-border-bottom">
                                <p><img :src="require('@/assets/images/3.svg')" height="20px" width="20px" /></p>
                                <div>
                                    <p>
                                        Save the changes: After making the updates, save the file with the changes you made in CSV
                                        format
                                    </p>
                                    <div style="color: #fa3a3a">
                                        <ol type="a">
                                            <li>NB: Do not change the column labelled “_id”</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <p class="flex items-center gap8 pv3 box-border-bottom">
                                <span><img :src="require('@/assets/images/4.svg')" height="20px" width="20px" /></span>
                                <span
                                    >Upload the updated file: After saving the updated file. come back here and click on the
                                    "Choose file" button below. When you click on it, select the file you just edited from your
                                    computer. Make sure it's saved in CSV format</span
                                >
                            </p>
                            <p class="flex items-center gap8 pv3 box-border-bottom">
                                <span><img :src="require('@/assets/images/5.svg')" height="20px" width="20px" /></span>
                                <span
                                    >Update your inventory: Look for an option that says "Update Inventory" and click on it. This
                                    will process the file you uploaded and update your inventory with the new product
                                    information</span
                                >
                            </p>
                            <p class="flex items-center gap8 pv3 box-border-bottom">
                                <span><img :src="require('@/assets/images/6.svg')" height="20px" width="20px" /></span>
                                <span
                                    >That's it! Now you've successfully updated the product information on SimpleBks in
                                    bulk.</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import { ref, onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import AppWrapper from '@/layout/AppWrapper'
export default {
    name: 'BulkUpdate',
    components: { AppWrapper },
    setup() {
        const store = useStore()
        const modalBulk = ref(false)
        const fileName = ref(null)
        const fileSize = ref(null)

        const orgData = computed(() => store?.state?.Auth?.userData)
        const showInventoryBulkUpdateModal = computed(() => store.state?.Inventory?.showInventoryBulkUpdateModal)

        const state = reactive({
            buttonDisabled: false,
            imageFile: null,
        })

        const formatBytes = (bytes, decimals = 2) => {
            if (bytes === 0) return '0 Bytes'
            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            const i = Math.floor(Math.log(bytes) / Math.log(k))
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        }

        const onFileChange = (e) => {
            state.imageFile = e.target.files?.[0]
            if (state.imageFile) {
                fileName.value = state.imageFile.name
                fileSize.value = formatBytes(state.imageFile.size)
                // console.log('Uploaded File:', fileName)
            }
        }

        const removeFile = () => {
            fileName.value = null
            fileSize.value = null
            state.imageFile = null
        }

        onMounted(() => {
            window.addEventListener('click', (event) => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (!(event.target.closest('.clickModalIcon') || event.target === modalIcon) && modalBulk.value === true) {
                    closeModal()
                }
            })
        })

        const onSubmit = () => {
            if (!state.imageFile) {
                return
            }
            state.buttonDisabled = true
            const formData = new FormData()
            formData.append('imageFile', state.imageFile)
            store.dispatch('Inventory/importInventoryBulkUpdate', formData).then((resp) => {
                if (resp) {
                    state.imageFile = null
                    fileName.value = null
                    fileSize.value = null
                    state.buttonDisabled = false
                }
            })
        }

        const downloadInventoryTemplate = () => {
            store.dispatch('Inventory/downloadInventoryTemplate').then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `${orgData.value?.org}-inventory-template.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const closeModal = () => {
            modalBulk.value = false
        }
        return {
            modalBulk,
            closeModal,
            downloadInventoryTemplate,
            state,
            onFileChange,
            fileName,
            fileSize,
            removeFile,
            onSubmit,
        }
    },
}
</script>
<style>
.styledBtn {
    background-color: #132c8c;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    padding: 13px 20px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 1rem;
}
</style>
